//lib
import React, { useEffect } from "react";

import { usePDF } from "@react-pdf/renderer";
import {
  Document,
  Page,
  Text,
  StyleSheet,
  View,
  Image,
} from "@react-pdf/renderer";
import * as generalConstants from "../../_constants/generalConstants";
import moment from "moment";

//style

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  flexRow: {
    flexDirection: "row",
  },
  flexRow1: {
    flexDirection: "row-reverse",
    marginTop: 20,
  },
  flexColumn: { flexDirection: "column", width: "65%" },
  flexColumn1: { flexDirection: "column", width: "40%" },
  flexColumn2: { flexDirection: "column", width: "30%", paddingRight: "3mm" },
  flexColumn3: { flexDirection: "column", width: "31%" },
  flexColumn4: {
    flexDirection: "column",
    textAlign: "center",
    alignContent: "center",
  },
  text: {
    fontSize: "3mm",
    width: "70%",
    fontFamily: "NotoSansSChinese",
    paddingRight: "3mm",
  },
  textLeft: {
    fontSize: "3mm",
    fontFamily: "NotoSansSChinese",
    width: "30%",
    paddingRight: "3mm",
  },
  text1: {
    fontSize: "2.5mm",
    fontFamily: "NotoSansSChinese",
  },
  text3: {
    fontSize: "3mm",
    textAlign: "left",
    fontFamily: "NotoSansSChinese",
    paddingRight: "3mm",
  },
  text4: {
    fontSize: "5mm",
    fontFamily: "NotoSansSChinese",
    fontWeight: "bold",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  pageNumber: {
    fontSize: "3mm",
    right: "5mm",
    bottom: "3mm",
    position: "absolute",
    textAlign: "center",
    color: "grey",
    fontFamily: "NotoSansSChinese",
  },
  pagePrinted: {
    fontSize: "3mm",
    left: "5mm",
    bottom: "3mm",
    position: "absolute",
    textAlign: "center",
    color: "grey",
    fontFamily: "NotoSansSChinese",
  },
  table: {
    display: "table",
    borderStyle: "solid",
    borderWidth: 1,
    width: "100%",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },

  tableRowNoBorder: {
    margin: "auto",
    flexDirection: "row",
    borderStyle: "solid",
  },

  tablePaymentCol1: {
    width: "25%",
  },
  tablePaymentCol2: {
    width: "25%",
  },
  tablePaymentCol3: {
    width: "25%",
  },
  tablePaymentCol4: {
    width: "25%",
  },

  tableCol1: {
    width: "25%",
  },
  tableCol2: {
    width: "45%",
  },
  tableCol3: {
    width: "30%",
  },
  tableCell: {
    fontSize: "2.5mm",
    fontFamily: "NotoSansSChinese",
    bottom: "-1mm",
    margin: "0.2mm",
  },
  tableCellRight: {
    fontSize: "2.5mm",
    fontFamily: "NotoSansSChinese",
    bottom: "-1mm",
    margin: "0.2mm",
    paddingRight: "3mm",
    textAlign: "right",
  },

  maroon075: {
    fontWeight: "bold",
    color: "rgba(149, 0, 0,0.75)",
  },
  grey: {
    fontWeight: "bold",
    color: "rgba(77, 77, 77)",
  },
});

const BillTable = ({ orderProcessInfo, paidAmount }) => {
  return (
    <View style={styles.table}>
      <View style={styles.tableRowNoBorder}>
        <View style={styles.tableCol1}>
          <Text style={styles.tableCell}>Services</Text>
        </View>

        <View style={styles.tableCol2}>
          <Text style={styles.tableCell}>Description</Text>
        </View>
        <View style={styles.tableCol3}>
          <Text style={styles.tableCell}>Amount (S$)</Text>
        </View>
      </View>

      {orderProcessInfo.store_medicine_costing_header.branch_medicine_price !==
        null &&
        orderProcessInfo.store_medicine_costing_header.branch_medicine_price !==
          0 && (
          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>Medicine</Text>
            </View>

            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Medicine</Text>
            </View>
            <View style={styles.tableCol3}>
              <Text style={styles.tableCell}>
                {orderProcessInfo.store_medicine_costing_header.status_type ===
                generalConstants.APPROVED_STATUS_TYPE
                  ? orderProcessInfo.store_medicine_costing_header.branch_medicine_price.toFixed(
                      2
                    )
                  : "0.00"}
              </Text>
            </View>
          </View>
        )}

      {orderProcessInfo.store_medicine_costing_header.branch_delivery_price !==
        null &&
        orderProcessInfo.store_medicine_costing_header.branch_delivery_price !==
          0 && (
          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>Delivery</Text>
            </View>

            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Delivery</Text>
            </View>
            <View style={styles.tableCol3}>
              <Text style={styles.tableCell}>
                {orderProcessInfo.store_medicine_costing_header.status_type ===
                generalConstants.APPROVED_STATUS_TYPE
                  ? orderProcessInfo.store_medicine_costing_header.branch_delivery_price.toFixed(
                      2
                    )
                  : "0.00"}
              </Text>
            </View>
          </View>
        )}

      {orderProcessInfo.store_medicine_costing_header.branch_brewing_price !==
        null &&
        orderProcessInfo.store_medicine_costing_header.branch_brewing_price !==
          0 && (
          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>Brewing</Text>
            </View>

            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Brewing</Text>
            </View>
            <View style={styles.tableCol3}>
              <Text style={styles.tableCell}>
                {orderProcessInfo.store_medicine_costing_header.status_type ===
                generalConstants.APPROVED_STATUS_TYPE
                  ? orderProcessInfo.store_medicine_costing_header.branch_brewing_price.toFixed(
                      2
                    )
                  : "0.00"}
              </Text>
            </View>
          </View>
        )}

      {orderProcessInfo.store_medicine_costing_header.is_gst_enable === true &&
        orderProcessInfo.store_medicine_costing_header.is_gst_inclusive ===
          true && (
          <>
            <View style={styles.tableRow}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableCol2}>
                <Text style={styles.tableCellRight}>
                  GST (
                  {orderProcessInfo.store_medicine_costing_header.gst_value}
                  %)
                </Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>
                  {orderProcessInfo.store_medicine_costing_header.gst_amt.toFixed(
                    2
                  )}
                </Text>
              </View>
            </View>

            <View style={styles.tableRowNoBorder}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableCol2}>
                <Text style={styles.tableCellRight}>
                  Total Amount Payable (inc.of GST)
                </Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>
                  {orderProcessInfo.store_medicine_costing_header.amt_with_gst.toFixed(
                    2
                  )}
                </Text>
              </View>
            </View>
            {orderProcessInfo.store_medicine_costing_header.amt_rounding !==
              null &&
              orderProcessInfo.store_medicine_costing_header.amt_rounding !==
                0 && (
                <View style={styles.tableRowNoBorder}>
                  <View style={styles.tableCol1}>
                    <Text style={styles.tableCell}></Text>
                  </View>

                  <View style={styles.tableCol2}>
                    <Text style={styles.tableCellRight}>Amount Rounding</Text>
                  </View>
                  <View style={styles.tableCol3}>
                    <Text style={styles.tableCell}>
                      {orderProcessInfo.store_medicine_costing_header.amt_rounding.toFixed(
                        2
                      )}
                    </Text>
                  </View>
                </View>
              )}

            <View style={styles.tableRowNoBorder}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableCol2}>
                <Text style={styles.tableCellRight}>Amount Paid</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>{paidAmount.toFixed(2)}</Text>
              </View>
            </View>

            <View style={styles.tableRowNoBorder}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableCol2}>
                <Text style={styles.tableCellRight}>Balance</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>
                  {(
                    orderProcessInfo.store_medicine_costing_header
                      .amt_with_gst - paidAmount
                  ).toFixed(2)}
                </Text>
              </View>
            </View>
          </>
        )}
      {orderProcessInfo.store_medicine_costing_header.is_gst_enable === true &&
        orderProcessInfo.store_medicine_costing_header.is_gst_inclusive ===
          false && (
          <>
            <View style={styles.tableRow}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableCol2}>
                <Text style={styles.tableCellRight}>Subtotal</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>
                  {orderProcessInfo.store_medicine_costing_header.amt_after_discount.toFixed(
                    2
                  )}
                </Text>
              </View>
            </View>

            <View style={styles.tableRowNoBorder}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableCol2}>
                <Text style={styles.tableCellRight}>
                  GST (
                  {orderProcessInfo.store_medicine_costing_header.gst_value} %)
                </Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>
                  {orderProcessInfo.store_medicine_costing_header.gst_amt.toFixed(
                    2
                  )}
                </Text>
              </View>
            </View>

            {orderProcessInfo.store_medicine_costing_header.amt_rounding !==
              0 && (
              <View style={styles.tableRowNoBorder}>
                <View style={styles.tableCol1}>
                  <Text style={styles.tableCell}></Text>
                </View>

                <View style={styles.tableCol2}>
                  <Text style={styles.tableCellRight}>Amount Rounding</Text>
                </View>
                <View style={styles.tableCol3}>
                  <Text style={styles.tableCell}>
                    {orderProcessInfo.store_medicine_costing_header.amt_rounding.toFixed(
                      2
                    )}
                  </Text>
                </View>
              </View>
            )}

            <View style={styles.tableRowNoBorder}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableCol2}>
                <Text style={styles.tableCellRight}>Total Amount Payable</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>
                  {orderProcessInfo.store_medicine_costing_header.amt_with_gst.toFixed(
                    2
                  )}
                </Text>
              </View>
            </View>

            <View style={styles.tableRowNoBorder}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableCol2}>
                <Text style={styles.tableCellRight}>Amount Paid</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}> {paidAmount.toFixed(2)}</Text>
              </View>
            </View>

            <View style={styles.tableRowNoBorder}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableCol2}>
                <Text style={styles.tableCellRight}>Balance</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>
                  {(
                    orderProcessInfo.store_medicine_costing_header
                      .amt_with_gst - paidAmount
                  ).toFixed(2)}
                </Text>
              </View>
            </View>
          </>
        )}
      {orderProcessInfo.store_medicine_costing_header.is_gst_enable === false &&
        orderProcessInfo.store_medicine_costing_header.is_gst_inclusive ===
          false && (
          <>
            <View style={styles.tableRow}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableCol2}>
                <Text style={styles.tableCellRight}>Total Amount Payable</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>
                  {orderProcessInfo.store_medicine_costing_header.amt_with_gst.toFixed(
                    2
                  )}
                </Text>
              </View>
            </View>
            {orderProcessInfo.store_medicine_costing_header.amt_rounding !==
              0 && (
              <View style={styles.tableRowNoBorder}>
                <View style={styles.tableCol1}>
                  <Text style={styles.tableCell}></Text>
                </View>

                <View style={styles.tableCol2}>
                  <Text style={styles.tableCellRight}>Amount Rounding</Text>
                </View>
                <View style={styles.tableCol3}>
                  <Text style={styles.tableCell}>
                    {orderProcessInfo.store_medicine_costing_header.amt_rounding.toFixed(
                      2
                    )}
                  </Text>
                </View>
              </View>
            )}

            <View style={styles.tableRowNoBorder}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableCol2}>
                <Text style={styles.tableCellRight}>Amount Paid</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}> {paidAmount.toFixed(2)}</Text>
              </View>
            </View>

            <View style={styles.tableRowNoBorder}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableCol2}>
                <Text style={styles.tableCellRight}>Balance</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>
                  {(
                    orderProcessInfo.store_medicine_costing_header
                      .amt_with_gst - paidAmount
                  ).toFixed(2)}
                </Text>
              </View>
            </View>
          </>
        )}
    </View>
  );
};

const PaymentTable = ({ orderProcessInfo }) => {
  const detailsWoDelete =
    orderProcessInfo.invoice_medicine_costing_details.filter(
      (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
    );
  const Rows = detailsWoDelete.map((item, index) => {
    return (
      <View style={styles.tableRow} key={index}>
        <View style={styles.tablePaymentCol1}>
          <Text style={styles.tableCell}>
            {item.receipt_header.order_number}
          </Text>
        </View>

        <View style={styles.tablePaymentCol2}>
          <Text style={styles.tableCell}>
            {moment.utc(item.payment_received_on).local().format("DD-MMM-YYYY")}
          </Text>
        </View>
        <View style={styles.tablePaymentCol3}>
          <Text style={styles.tableCell}>{item.payment_amount.toFixed(2)}</Text>
        </View>
        <View style={styles.tablePaymentCol4}>
          <Text style={styles.tableCell}>{item.payment_method_name}</Text>
        </View>
      </View>
    );
  });
  if (Rows.length) {
    return (
      <View
        style={{
          marginTop: 10,
        }}>
        <View style={styles.table}>
          <View style={styles.tableRowNoBorder}>
            <View style={styles.tablePaymentCol1}>
              <Text style={styles.tableCell}>Receipt No</Text>
            </View>

            <View style={styles.tablePaymentCol2}>
              <Text style={styles.tableCell}>Payment Date</Text>
            </View>
            <View style={styles.tablePaymentCol3}>
              <Text style={styles.tableCell}>Paid Amount</Text>
            </View>
            <View style={styles.tablePaymentCol4}>
              <Text style={styles.tableCell}>Payment Method</Text>
            </View>
          </View>
          {Rows}
        </View>
      </View>
    );
  }
  return <></>;
};

const PDFPage = ({ TCMLogo, orderProcessInfo, paidAmount }) => {
  return (
    <Document>
      <Page size="A5" orientation="portrait" style={styles.body}>
        <View style={styles.flexRow} fixed>
          <View style={styles.flexColumn2} fixed>
            <Image
              src={TCMLogo}
              style={{ height: "50px", width: "100px" }}
              fixed
            />
          </View>
          {orderProcessInfo.store_medicine_costing_header.organisation_branch
            ?.branch !== null && (
            <View style={styles.flexColumn1} fixed>
              <Text style={styles.text1} fixed>
                {
                  orderProcessInfo.store_medicine_costing_header
                    .organisation_branch.branch.desc
                }
              </Text>

              {orderProcessInfo.store_medicine_costing_header
                .organisation_branch.branch.postal_code &&
              orderProcessInfo.store_medicine_costing_header.organisation_branch
                .branch.blk_no &&
              orderProcessInfo.store_medicine_costing_header.organisation_branch
                .branch.road_name ? (
                <Text style={styles.text1} fixed>
                  {
                    orderProcessInfo.store_medicine_costing_header
                      .organisation_branch.branch.blk_no
                  }{" "}
                  {
                    orderProcessInfo.store_medicine_costing_header
                      .organisation_branch.branch.road_name
                  }{" "}
                  {orderProcessInfo.store_medicine_costing_header
                    .organisation_branch.branch.building
                    ? orderProcessInfo.store_medicine_costing_header
                        .organisation_branch.branch.building + " "
                    : null}
                  {
                    orderProcessInfo.store_medicine_costing_header
                      .organisation_branch.branch.address_details
                  }{" "}
                  SINGAPORE{" "}
                  {
                    orderProcessInfo.store_medicine_costing_header
                      .organisation_branch.branch.postal_code
                  }
                </Text>
              ) : (
                <Text style={styles.text1} fixed>
                  {
                    orderProcessInfo.store_medicine_costing_header
                      .organisation_branch.branch.address
                  }{" "}
                  {
                    orderProcessInfo.store_medicine_costing_header
                      .organisation_branch.branch.address_details
                  }
                </Text>
              )}
              <Text style={styles.text1} fixed>
                Tel No:{" "}
                {
                  orderProcessInfo.store_medicine_costing_header
                    .organisation_branch.branch.contact_number
                }
              </Text>
              {orderProcessInfo.organisation.gst_reg_no !== null && (
                <Text style={styles.text1} fixed>
                  GST No: {orderProcessInfo.organisation.gst_reg_no}
                </Text>
              )}
            </View>
          )}

          {/* <View style={styles.flexColumn1} fixed>
            <Text style={styles.text1} fixed>
              {defaultBranch.name}
            </Text>

            <Text style={styles.text1} fixed>
              {defaultBranch.address}
              {defaultBranch.address_details ? "," : " "}
              {defaultBranch.address_details}
            </Text>
            <Text style={styles.text1} fixed>
              Tel No: {defaultBranch.contact_number}
            </Text>
            <Text style={styles.text1} fixed>
              GST No: {organisationInfo.gst_reg_no}
            </Text>
          </View> */}
        </View>
        {orderProcessInfo.store_medicine_costing_header.is_gst_enable ===
          true && (
          <View style={styles.flexColumn4} fixed>
            <Text style={styles.text4} fixed>
              TAX INVOICE
            </Text>
          </View>
        )}
        <View style={styles.flexRow} fixed>
          <Text style={styles.text} fixed>
            Invoice No: {orderProcessInfo.order_number}
          </Text>
          <Text style={styles.textLeft} fixed>
            Date:{" "}
            {moment
              .utc(orderProcessInfo.created_on)
              .local()
              .format("DD-MMM-YYYY")}
          </Text>
        </View>
        {/* 
        <View style={styles.flexRow} fixed>
          <Text style={styles.text} fixed>
            Name: {orderProcessInfo.store_medicine_costing_header.name}
          </Text>
        </View> */}

        <BillTable
          orderProcessInfo={orderProcessInfo}
          paidAmount={paidAmount}
        />
        <PaymentTable orderProcessInfo={orderProcessInfo} />

        <Text style={styles.pagePrinted} fixed>
          {`${
            orderProcessInfo.first_print_on === null
              ? "Printed on:"
              : "Reprinted on:"
          } ${moment().format("DD-MMM-YYYY")}`}
        </Text>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};
let printNum = 0;
const InvoiceMedicineCostingPDF = ({
  t,
  TCMLogo,
  orderProcessInfo,
  paidAmount,
  paymentOptions,
  defaultBranch,
  print,
  setPrintingDate = () => null,
  allowPrint,
}) => {
  const [instance, updateInstance] = usePDF({
    document: (
      <PDFPage
        TCMLogo={TCMLogo}
        orderProcessInfo={orderProcessInfo}
        paidAmount={paidAmount}
        paymentOptions={paymentOptions}
        defaultBranch={defaultBranch}
      />
    ),
  });
  useEffect(() => {
    if (
      !instance.loading &&
      instance.url &&
      print !== printNum &&
      !allowPrint
    ) {
      printNum = print;
      //window.open(instance.url, "_blank", "noreferrer");

      setPrintingDate();
    }
  }, [instance.loading, instance.url, printNum, print, allowPrint]);

  useEffect(() => {
    if (!instance.loading && instance.url && allowPrint) {
      //make sure update print date and allow to print
      window.open(instance.url, "_blank", "noreferrer");
    }
  }, [instance.loading, instance.url, allowPrint]);

  return <></>;
  // if (print !== printNum) {
  //   return (
  //     <BlobProvider
  //       document={
  //         <PDFPage
  //           TCMLogo={TCMLogo}
  //           orderProcessInfo={orderProcessInfo}
  //           paidAmount={paidAmount}
  //           paymentOptions={paymentOptions}
  //           defaultBranch={defaultBranch}
  //         />
  //       }>
  //       {({ blob, url, loading, error }) => {
  //         // var file = new blob();
  //         if (!loading && blob && print !== printNum) {
  //           var fileURL = [];
  //           printNum = print;
  //           fileURL = URL.createObjectURL(blob);
  //           window.open(fileURL, "_blank", "noreferrer");
  //           setPrintingDate();
  //         }

  //         // Do whatever you need with blob here
  //         return <></>;
  //       }}
  //     </BlobProvider>
  //   );
  // } else {
  //   return <></>;
  // }
};

export default InvoiceMedicineCostingPDF;
