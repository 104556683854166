//lib
import React, { useEffect } from "react";
import { usePDF } from "@react-pdf/renderer";
import {
  Document,
  Page,
  Text,
  StyleSheet,
  View,
  Image,
} from "@react-pdf/renderer";
import * as generalConstants from "../../_constants/generalConstants";
import moment from "moment";

//style

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  flexRow: {
    flexDirection: "row",
  },
  flexRow1: {
    flexDirection: "row-reverse",
    marginTop: 20,
  },
  flexColumn: { flexDirection: "column", width: "65%" },
  flexColumn1: { flexDirection: "column", width: "40%" },
  flexColumn2: { flexDirection: "column", width: "30%", paddingRight: "3mm" },
  flexColumn3: { flexDirection: "column", width: "31%" },

  flexColumn4: {
    flexDirection: "column",
    textAlign: "center",
    alignContent: "center",
  },
  text: {
    fontSize: "3mm",
    width: "70%",
    fontFamily: "NotoSansSChinese",
    paddingRight: "3mm",
  },
  textLeft: {
    fontSize: "3mm",
    fontFamily: "NotoSansSChinese",
    width: "30%",
    paddingRight: "3mm",
  },
  text1: {
    fontSize: "2.5mm",
    fontFamily: "NotoSansSChinese",
  },
  text3: {
    fontSize: "3mm",
    textAlign: "left",
    fontFamily: "NotoSansSChinese",
    paddingRight: "3mm",
  },
  text4: {
    fontSize: "5mm",
    fontFamily: "NotoSansSChinese",
    fontWeight: "bold",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  pageNumber: {
    fontSize: "3mm",
    right: "5mm",
    bottom: "3mm",
    position: "absolute",
    textAlign: "center",
    color: "grey",
    fontFamily: "NotoSansSChinese",
  },
  pagePrinted: {
    fontSize: "3mm",
    left: "5mm",
    bottom: "3mm",
    position: "absolute",
    textAlign: "center",
    color: "grey",
    fontFamily: "NotoSansSChinese",
  },
  table: {
    display: "table",
    borderStyle: "solid",
    borderWidth: 1,
    width: "100%",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },

  tableRowNoBorder: {
    margin: "auto",
    flexDirection: "row",
    borderStyle: "solid",
  },

  tablePaymentCol1: {
    width: "25%",
  },
  tablePaymentCol2: {
    width: "25%",
  },
  tablePaymentCol3: {
    width: "25%",
  },
  tablePaymentCol4: {
    width: "25%",
  },

  tableCol1: {
    width: "25%",
  },
  tableCol2: {
    width: "45%",
  },
  tableCol3: {
    width: "30%",
  },
  tableCell: {
    fontSize: "3mm",
    fontFamily: "NotoSansSChinese",
    bottom: "-1mm",
    margin: "0.2mm",
  },
  tableCellRight: {
    fontSize: "3mm",
    fontFamily: "NotoSansSChinese",
    bottom: "-1mm",
    margin: "0.2mm",
    textAlign: "right",
    paddingRight: "3mm",
  },

  maroon075: {
    fontWeight: "bold",
    color: "rgba(149, 0, 0,0.75)",
  },
  grey: {
    fontWeight: "bold",
    color: "rgba(77, 77, 77)",
  },
});

const BillTable = ({
  storeHeader,
  paidAmount,
  treatmentDetail = null,
  miscDetail = null,
}) => {
  return (
    <View style={styles.table}>
      <View style={styles.tableRowNoBorder}>
        <View style={styles.tableCol1}>
          <Text style={styles.tableCell}>Services</Text>
        </View>

        <View style={styles.tableCol2}>
          <Text style={styles.tableCell}>Description</Text>
        </View>
        <View style={styles.tableCol3}>
          <Text style={styles.tableCell}>Amount (S$)</Text>
        </View>
      </View>
      {((storeHeader.consultation_total_price !== null &&
        storeHeader.consultation_total_price !== 0) ||
        (storeHeader.consultation_extra_amt !== null &&
          storeHeader.consultation_extra_amt !== 0)) && (
        <View style={styles.tableRow}>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Consultation</Text>
          </View>

          <View style={styles.tableCol2}>
            <Text style={styles.tableCell}>Consultation</Text>
          </View>
          <View style={styles.tableCol3}>
            <Text style={styles.tableCell}>
              {(
                storeHeader.consultation_total_price +
                storeHeader.consultation_extra_amt
              ).toFixed(2)}
            </Text>
          </View>
        </View>
      )}
      {storeHeader.consultation_discount_amt !== null &&
        storeHeader.consultation_discount_amt !== 0 && (
          <View style={styles.tableRowNoBorder}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}></Text>
            </View>

            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Discount</Text>
            </View>
            <View style={styles.tableCol3}>
              <Text style={styles.tableCell}>
                ({storeHeader.consultation_discount_amt.toFixed(2)})
              </Text>
            </View>
          </View>
        )}
      {((storeHeader.medicine_total_price !== null &&
        storeHeader.medicine_total_price !== 0) ||
        (storeHeader.medicine_extra_amt !== null &&
          storeHeader.medicine_extra_amt !== 0)) && (
        <View style={styles.tableRow}>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Medicine</Text>
          </View>

          <View style={styles.tableCol2}>
            <Text style={styles.tableCell}>Medicine</Text>
          </View>
          <View style={styles.tableCol3}>
            <Text style={styles.tableCell}>
              {(
                storeHeader.medicine_total_price +
                storeHeader.medicine_extra_amt
              ).toFixed(2)}
            </Text>
          </View>
        </View>
      )}
      {storeHeader.medicine_discount_amt !== null &&
        storeHeader.medicine_discount_amt !== 0 && (
          <View style={styles.tableRowNoBorder}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}></Text>
            </View>

            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Discount</Text>
            </View>
            <View style={styles.tableCol3}>
              <Text style={styles.tableCell}>
                ({storeHeader.medicine_discount_amt.toFixed(2)})
              </Text>
            </View>
          </View>
        )}
      {((storeHeader.acupuncture_total_price !== null &&
        storeHeader.acupuncture_total_price !== 0) ||
        (storeHeader.acupuncture_extra_amt !== null &&
          storeHeader.acupuncture_extra_amt !== 0)) && (
        <View style={styles.tableRow}>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Acupuncture</Text>
          </View>

          <View style={styles.tableCol2}>
            <Text style={styles.tableCell}>Acupuncture</Text>
          </View>
          <View style={styles.tableCol3}>
            <Text style={styles.tableCell}>
              {(
                storeHeader.acupuncture_total_price +
                storeHeader.acupuncture_extra_amt
              ).toFixed(2)}
            </Text>
          </View>
        </View>
      )}
      {storeHeader.acupuncture_discount_amt !== null &&
        storeHeader.acupuncture_discount_amt !== 0 && (
          <View style={styles.tableRowNoBorder}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}></Text>
            </View>

            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Discount</Text>
            </View>
            <View style={styles.tableCol3}>
              <Text style={styles.tableCell}>
                ({storeHeader.acupuncture_discount_amt.toFixed(2)})
              </Text>
            </View>
          </View>
        )}

      {((storeHeader.delivery_total_price_for_costing !== null &&
        storeHeader.delivery_total_price_for_costing !== 0) ||
        (storeHeader.delivery_extra_amt !== null &&
          storeHeader.delivery_extra_amt !== 0)) && (
        <View style={styles.tableRow}>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Delivery</Text>
          </View>

          <View style={styles.tableCol2}>
            <Text style={styles.tableCell}>Delivery</Text>
          </View>
          <View style={styles.tableCol3}>
            <Text style={styles.tableCell}>
              {(
                storeHeader.delivery_total_price_for_costing +
                storeHeader.delivery_extra_amt
              ).toFixed(2)}
            </Text>
          </View>
        </View>
      )}
      {storeHeader.delivery_discount_amt !== null &&
        storeHeader.delivery_discount_amt !== 0 && (
          <View style={styles.tableRowNoBorder}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}></Text>
            </View>

            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Discount</Text>
            </View>
            <View style={styles.tableCol3}>
              <Text style={styles.tableCell}>
                ( {storeHeader.delivery_discount_amt.toFixed(2)})
              </Text>
            </View>
          </View>
        )}
      {((storeHeader.brewing_total_price_for_costing !== null &&
        storeHeader.brewing_total_price_for_costing !== 0) ||
        (storeHeader.brewing_extra_amt !== null &&
          storeHeader.brewing_extra_amt !== 0)) && (
        <View style={styles.tableRow}>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Brewing</Text>
          </View>

          <View style={styles.tableCol2}>
            <Text style={styles.tableCell}>Brewing</Text>
          </View>
          <View style={styles.tableCol3}>
            <Text style={styles.tableCell}>
              {(
                storeHeader.brewing_total_price_for_costing +
                storeHeader.brewing_extra_amt
              ).toFixed(2)}
            </Text>
          </View>
        </View>
      )}
      {storeHeader.brewing_discount_amt !== null &&
        storeHeader.brewing_discount_amt !== 0 && (
          <View style={styles.tableRowNoBorder}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}></Text>
            </View>

            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Discount</Text>
            </View>
            <View style={styles.tableCol3}>
              <Text style={styles.tableCell}>
                ({storeHeader.brewing_discount_amt.toFixed(2)})
              </Text>
            </View>
          </View>
        )}
      {storeHeader.treatment_total_price !== null &&
        storeHeader.treatment_total_price !== 0 &&
        treatmentDetail.map((treatmentDetailItem, treatmentDetailKey) => (
          <div key={"treatmentDetail" + treatmentDetailKey}>
            {treatmentDetailKey === 0 && (
              <View style={styles.tableRow}>
                <View style={styles.tableCol1}>
                  <Text style={styles.tableCell}>Treatment</Text>
                </View>

                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>
                    {treatmentDetailItem.name}
                  </Text>
                </View>
                <View style={styles.tableCol3}>
                  <Text style={styles.tableCell}>
                    {treatmentDetailItem.amount}
                  </Text>
                </View>
              </View>
            )}

            {treatmentDetailKey !== 0 && (
              <View style={styles.tableRowNoBorder}>
                <View style={styles.tableCol1}>
                  <Text style={styles.tableCell}></Text>
                </View>

                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>
                    {" "}
                    {treatmentDetailItem.name}
                  </Text>
                </View>
                <View style={styles.tableCol3}>
                  <Text style={styles.tableCell}>
                    {treatmentDetailItem.amount}
                  </Text>
                </View>
              </View>
            )}
          </div>
        ))}
      {storeHeader.treatment_discount_amt !== null &&
        storeHeader.treatment_discount_amt !== 0 && (
          <View style={styles.tableRowNoBorder}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}></Text>
            </View>

            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Discount</Text>
            </View>
            <View style={styles.tableCol3}>
              <Text style={styles.tableCell}>
                ({storeHeader.treatment_discount_amt.toFixed(2)})
              </Text>
            </View>
          </View>
        )}
      {storeHeader.misc_total_price !== null &&
        storeHeader.misc_total_price !== 0 &&
        miscDetail.map((miscDetailItem, miscDetailKey) => (
          <div key={"miscDetail" + miscDetailKey}>
            {miscDetailKey === 0 && (
              <View style={styles.tableRow}>
                <View style={styles.tableCol1}>
                  <Text style={styles.tableCell}>Misc</Text>
                </View>

                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>{miscDetailItem.name}</Text>
                </View>
                <View style={styles.tableCol3}>
                  <Text style={styles.tableCell}>{miscDetailItem.amount}</Text>
                </View>
              </View>
            )}

            {miscDetailKey !== 0 && (
              <View style={styles.tableRowNoBorder}>
                <View style={styles.tableCol1}>
                  <Text style={styles.tableCell}></Text>
                </View>

                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}> {miscDetailItem.name}</Text>
                </View>
                <View style={styles.tableCol3}>
                  <Text style={styles.tableCell}>{miscDetailItem.amount}</Text>
                </View>
              </View>
            )}
          </div>
        ))}

      {storeHeader.misc_discount_amt !== null &&
        storeHeader.misc_discount_amt !== 0 && (
          <View style={styles.tableRowNoBorder}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}></Text>
            </View>

            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Discount</Text>
            </View>
            <View style={styles.tableCol3}>
              <Text style={styles.tableCell}>
                ({storeHeader.misc_discount_amt.toFixed(2)})
              </Text>
            </View>
          </View>
        )}

      {storeHeader.is_gst_enable === true &&
        storeHeader.is_gst_inclusive === true && (
          <>
            {storeHeader.discount_in_percent !== 0 &&
              storeHeader.discount_in_percent !== null && (
                <View style={styles.tableRow}>
                  <View style={styles.tableCol1}>
                    <Text style={styles.tableCell}></Text>
                  </View>

                  <View style={styles.tableCol2}>
                    <Text style={styles.tableCellRight}>Discount %</Text>
                  </View>
                  <View style={styles.tableCol3}>
                    <Text style={styles.tableCell}>
                      {storeHeader.discount_in_percent.toFixed(2)} %
                    </Text>
                  </View>
                </View>
              )}

            <View
              style={
                storeHeader.discount_in_percent
                  ? styles.tableRowNoBorder
                  : styles.tableRow
              }>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableCol2}>
                <Text style={styles.tableCellRight}>
                  GST ({storeHeader.gst_value}%)
                </Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>
                  {storeHeader.gst_amt.toFixed(2)}
                </Text>
              </View>
            </View>

            <View style={styles.tableRowNoBorder}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableCol2}>
                <Text style={styles.tableCellRight}>
                  Total Amount Payable (inc.of GST)
                </Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>
                  {storeHeader.amt_with_gst.toFixed(2)}
                </Text>
              </View>
            </View>
            {storeHeader.amt_rounding !== null &&
              storeHeader.amt_rounding !== 0 && (
                <View style={styles.tableRowNoBorder}>
                  <View style={styles.tableCol1}>
                    <Text style={styles.tableCell}></Text>
                  </View>

                  <View style={styles.tableCol2}>
                    <Text style={styles.tableCellRight}>Amount Rounding</Text>
                  </View>
                  <View style={styles.tableCol3}>
                    <Text style={styles.tableCell}>
                      {storeHeader.amt_rounding.toFixed(2)}
                    </Text>
                  </View>
                </View>
              )}

            <View style={styles.tableRowNoBorder}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableCol2}>
                <Text style={styles.tableCellRight}>Amount Paid</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>{paidAmount.toFixed(2)}</Text>
              </View>
            </View>

            <View style={styles.tableRowNoBorder}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableCol2}>
                <Text style={styles.tableCellRight}>Balance</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>
                  {(storeHeader.amt_with_gst - paidAmount).toFixed(2)}
                </Text>
              </View>
            </View>
          </>
        )}
      {storeHeader.is_gst_enable === true &&
        storeHeader.is_gst_inclusive === false && (
          <>
            {storeHeader.discount_in_percent !== null &&
              storeHeader.discount_in_percent !== 0 && (
                <View style={styles.tableRow}>
                  <View style={styles.tableCol1}>
                    <Text style={styles.tableCell}></Text>
                  </View>

                  <View style={styles.tableCol2}>
                    <Text style={styles.tableCellRight}>Discount %</Text>
                  </View>
                  <View style={styles.tableCol3}>
                    <Text style={styles.tableCell}>
                      {storeHeader.discount_in_percent.toFixed(2)} %
                    </Text>
                  </View>
                </View>
              )}
            <View
              style={
                storeHeader.discount_in_percent
                  ? styles.tableRowNoBorder
                  : styles.tableRow
              }>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableCol2}>
                <Text style={styles.tableCellRight}>Subtotal</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>
                  {storeHeader.amt_after_discount.toFixed(2)}
                </Text>
              </View>
            </View>

            <View style={styles.tableRowNoBorder}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableCol2}>
                <Text style={styles.tableCellRight}>
                  GST ({storeHeader.gst_value} %)
                </Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>
                  {storeHeader.gst_amt.toFixed(2)}
                </Text>
              </View>
            </View>

            {storeHeader.amt_rounding !== null &&
              storeHeader.amt_rounding !== 0 && (
                <View style={styles.tableRowNoBorder}>
                  <View style={styles.tableCol1}>
                    <Text style={styles.tableCell}></Text>
                  </View>

                  <View style={styles.tableCol2}>
                    <Text style={styles.tableCellRight}>Amount Rounding</Text>
                  </View>
                  <View style={styles.tableCol3}>
                    <Text style={styles.tableCell}>
                      {storeHeader.amt_rounding.toFixed(2)}
                    </Text>
                  </View>
                </View>
              )}

            <View style={styles.tableRowNoBorder}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableCol2}>
                <Text style={styles.tableCellRight}>Total Amount Payable</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>
                  {storeHeader.amt_with_gst.toFixed(2)}
                </Text>
              </View>
            </View>

            <View style={styles.tableRowNoBorder}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableCol2}>
                <Text style={styles.tableCellRight}>Amount Paid</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}> {paidAmount.toFixed(2)}</Text>
              </View>
            </View>

            <View style={styles.tableRowNoBorder}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableCol2}>
                <Text style={styles.tableCellRight}>Balance</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>
                  {(storeHeader.amt_with_gst - paidAmount).toFixed(2)}
                </Text>
              </View>
            </View>
          </>
        )}
      {storeHeader.is_gst_enable === false &&
        storeHeader.is_gst_inclusive === false && (
          <>
            {storeHeader.discount_in_percent !== null &&
              storeHeader.discount_in_percent !== 0 && (
                <View style={styles.tableRow}>
                  <View style={styles.tableCol1}>
                    <Text style={styles.tableCell}></Text>
                  </View>

                  <View style={styles.tableCol2}>
                    <Text style={styles.tableCellRight}>Discount %</Text>
                  </View>
                  <View style={styles.tableCol3}>
                    <Text style={styles.tableCell}>
                      {storeHeader.discount_in_percent.toFixed(2)} %
                    </Text>
                  </View>
                </View>
              )}
            <View
              style={
                storeHeader.discount_in_percent
                  ? styles.tableRowNoBorder
                  : styles.tableRow
              }>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableCol2}>
                <Text style={styles.tableCellRight}>Total Amount Payable</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>
                  {storeHeader.amt_with_gst.toFixed(2)}
                </Text>
              </View>
            </View>
            {storeHeader.amt_rounding !== null &&
              storeHeader.amt_rounding !== 0 && (
                <View style={styles.tableRowNoBorder}>
                  <View style={styles.tableCol1}>
                    <Text style={styles.tableCell}></Text>
                  </View>

                  <View style={styles.tableCol2}>
                    <Text style={styles.tableCellRight}>Amount Rounding</Text>
                  </View>
                  <View style={styles.tableCol3}>
                    <Text style={styles.tableCell}>
                      {storeHeader.amt_rounding.toFixed(2)}
                    </Text>
                  </View>
                </View>
              )}

            <View style={styles.tableRowNoBorder}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableCol2}>
                <Text style={styles.tableCellRight}>Amount Paid</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}> {paidAmount.toFixed(2)}</Text>
              </View>
            </View>

            <View style={styles.tableRowNoBorder}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={styles.tableCol2}>
                <Text style={styles.tableCellRight}>Balance</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>
                  {(storeHeader.amt_with_gst - paidAmount).toFixed(2)}
                </Text>
              </View>
            </View>
          </>
        )}
    </View>
  );
};

const PaymentTable = ({ invoiveHeader }) => {
  const detailsWoDelete = invoiveHeader.invoice_details.filter(
    (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
  );
  const Rows = detailsWoDelete.map((item, index) => {
    return (
      <View style={styles.tableRow} key={index}>
        <View style={styles.tablePaymentCol1}>
          <Text style={styles.tableCell}>
            {item.receipt_header.order_number}
          </Text>
        </View>

        <View style={styles.tablePaymentCol2}>
          <Text style={styles.tableCell}>
            {moment.utc(item.payment_received_on).local().format("DD-MMM-YYYY")}
          </Text>
        </View>
        <View style={styles.tablePaymentCol3}>
          <Text style={styles.tableCell}>{item.payment_amount.toFixed(2)}</Text>
        </View>
        <View style={styles.tablePaymentCol4}>
          <Text style={styles.tableCell}>{item.payment_method_name}</Text>
        </View>
      </View>
    );
  });
  if (Rows.length) {
    return (
      <View
        style={{
          marginTop: 10,
        }}>
        <View style={styles.table}>
          <View style={styles.tableRowNoBorder}>
            <View style={styles.tablePaymentCol1}>
              <Text style={styles.tableCell}>Receipt No</Text>
            </View>

            <View style={styles.tablePaymentCol2}>
              <Text style={styles.tableCell}>Payment Date</Text>
            </View>
            <View style={styles.tablePaymentCol3}>
              <Text style={styles.tableCell}>Paid Amount</Text>
            </View>
            <View style={styles.tablePaymentCol4}>
              <Text style={styles.tableCell}>Payment Method</Text>
            </View>
          </View>
          {Rows}
        </View>
      </View>
    );
  }
  return <></>;
};

const AddressHeader = ({ invoiveHeader, storeHeader }) => {
  if (storeHeader.work_order_type === generalConstants.STORE_WORK_ORDER_TYPE) {
    if (invoiveHeader.organisation.is_freelance_organisation === true) {
      return (
        <View style={styles.flexColumn1} fixed>
          <Text style={styles.text1} fixed>
            {storeHeader.staff.name}
          </Text>
          {storeHeader.staff.postal_code &&
          storeHeader.staff.blk_no &&
          storeHeader.staff.road_name ? (
            <Text style={styles.text1} fixed>
              {storeHeader.staff.blk_no} {storeHeader.staff.road_name}{" "}
              {storeHeader.staff.building
                ? storeHeader.staff.building + " "
                : null}
              {storeHeader.staff.address_details} SINGAPORE{" "}
              {storeHeader.staff.postal_code}
            </Text>
          ) : (
            <Text style={styles.text1} fixed>
              {storeHeader.staff.address} {storeHeader.staff.address_details}
            </Text>
          )}
          <Text style={styles.text1} fixed>
            Tel No: {storeHeader.staff.contact_number}
          </Text>
        </View>
      );
    } else {
      return (
        <View style={styles.flexColumn1} fixed>
          <Text style={styles.text1} fixed>
            {invoiveHeader.organisation.desc}
          </Text>

          {invoiveHeader.organisation.gst_reg_no !== null && (
            <Text style={styles.text1} fixed>
              GST No: {invoiveHeader.organisation.gst_reg_no}
            </Text>
          )}
        </View>
      );
    }
  }

  if (storeHeader.work_order_type === generalConstants.VISIT_WORK_ORDER_TYPE) {
    if (invoiveHeader.organisation.is_freelance_organisation === true) {
      return (
        <View style={styles.flexColumn1} fixed>
          <Text style={styles.text1} fixed>
            {storeHeader.physician.name}
          </Text>

          {storeHeader.physician.postal_code &&
          storeHeader.physician.blk_no &&
          storeHeader.physician.road_name ? (
            <Text style={styles.text1} fixed>
              {storeHeader.physician.blk_no} {storeHeader.physician.road_name}{" "}
              {storeHeader.physician.building
                ? storeHeader.physician.building + " "
                : null}
              {storeHeader.physician.address_details} SINGAPORE{" "}
              {storeHeader.physician.postal_code}
            </Text>
          ) : (
            <Text style={styles.text1} fixed>
              {storeHeader.physician.address}{" "}
              {storeHeader.physician.address_details}
            </Text>
          )}
          <Text style={styles.text1} fixed>
            Tel No: {storeHeader.physician.contact_number}
          </Text>
        </View>
      );
    } else {
      return (
        <View style={styles.flexColumn1} fixed>
          <Text style={styles.text1} fixed>
            {storeHeader.visit_branch.desc}
          </Text>

          {storeHeader.visit_branch.postal_code &&
          storeHeader.visit_branch.blk_no &&
          storeHeader.visit_branch.road_name ? (
            <Text style={styles.text1} fixed>
              {storeHeader.visit_branch.blk_no}{" "}
              {storeHeader.visit_branch.road_name}{" "}
              {storeHeader.visit_branch.building
                ? storeHeader.visit_branch.building + " "
                : null}
              {storeHeader.visit_branch.address_details} SINGAPORE{" "}
              {storeHeader.visit_branch.postal_code}
            </Text>
          ) : (
            <Text style={styles.text1} fixed>
              {storeHeader.visit_branch.address}{" "}
              {storeHeader.visit_branch.address_details}
            </Text>
          )}
          <Text style={styles.text1} fixed>
            Tel No: {storeHeader.visit_branch.contact_number}
          </Text>
          {storeHeader.organisation.gst_reg_no !== null && (
            <Text style={styles.text1} fixed>
              GST No: {storeHeader.organisation.gst_reg_no}
            </Text>
          )}
        </View>
      );
    }
  }
  return <View style={styles.flexColumn1} fixed></View>;
};

const PDFPage = ({
  TCMLogo,
  invoiveHeader,
  storeHeader,
  paidAmount,
  miscDetail,
  treatmentDetail,
}) => {
  const NRIC = storeHeader.staff_identification_number;
  const NRICLength = NRIC ? NRIC.length : 0;
  let NRICLastFourChar = NRIC ? NRIC.substring(NRICLength - 4) : null;

  return (
    <Document>
      <Page size="A5" orientation="portrait" style={styles.body}>
        <View style={styles.flexRow} fixed>
          <View style={styles.flexColumn2} fixed>
            <Image
              src={TCMLogo}
              style={{ height: "50px", width: "100px" }}
              fixed
            />
          </View>
          <AddressHeader
            invoiveHeader={invoiveHeader}
            storeHeader={storeHeader}
          />
        </View>
        {storeHeader.is_gst_enable === true && (
          <View style={styles.flexColumn4} fixed>
            <Text style={styles.text4} fixed>
              TAX INVOICE
            </Text>
          </View>
        )}
        <View style={styles.flexRow} fixed>
          <Text style={styles.text} fixed>
            Invoice No: {invoiveHeader.order_number}
          </Text>
          <Text style={styles.textLeft} fixed>
            Date:{" "}
            {moment.utc(invoiveHeader.created_on).local().format("DD-MMM-YYYY")}
          </Text>
        </View>

        <View style={styles.flexRow} fixed>
          <Text style={styles.text} fixed>
            Name: {storeHeader.staff_name}
          </Text>

          {storeHeader.work_order_type ===
            generalConstants.VISIT_WORK_ORDER_TYPE && (
            <Text style={styles.textLeft} fixed>
              NRIC: {NRICLastFourChar}
            </Text>
          )}
        </View>

        <BillTable
          storeHeader={storeHeader}
          paidAmount={paidAmount}
          treatmentDetail={treatmentDetail}
          miscDetail={miscDetail}
        />
        <PaymentTable invoiveHeader={invoiveHeader} />

        <Text style={styles.pagePrinted} fixed>
          {`${
            invoiveHeader.first_print_on === null
              ? "Printed on:"
              : "Reprinted on:"
          } ${moment().format("DD-MMM-YYYY")}`}
        </Text>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

let printNum = 0;

const InvoicePDF = ({
  t,
  TCMLogo,
  invoiveHeader,
  storeHeader,
  paidAmount,
  miscDetail,
  treatmentDetail,
  paymentOptions,
  print,
  setPrintingDate = () => null,
  allowPrint,
  setAllowPrint = () => null,
  onHandleBlob = () => null,
  handleBlob = false,
}) => {
  const [instance, updateInstance] = usePDF({
    document: (
      <PDFPage
        TCMLogo={TCMLogo}
        invoiveHeader={invoiveHeader}
        storeHeader={storeHeader}
        paidAmount={paidAmount}
        miscDetail={miscDetail}
        treatmentDetail={treatmentDetail}
        paymentOptions={paymentOptions}
      />
    ),
  });

  useEffect(() => {
    if (
      !instance.loading &&
      instance.url &&
      print !== printNum &&
      !allowPrint
    ) {
      printNum = print;
      //window.open(instance.url, "_blank", "noreferrer");

      setPrintingDate();
    }
  }, [instance.loading, instance.url, printNum, print, allowPrint]);

  useEffect(() => {
    if (!instance.loading && instance.url && allowPrint) {
      //make sure update print date and allow to print
      window.open(instance.url, "_blank", "noreferrer");
      setAllowPrint(false);
      if (handleBlob === true) {
        onHandleBlob(instance);
      }
    }
  }, [instance.loading, instance.url, allowPrint, handleBlob]);

  return <></>;
  // if (print !== printNum) {
  //   return (
  //     <BlobProvider
  //       document={
  //         <PDFPage
  //           TCMLogo={TCMLogo}
  //           invoiveHeader={invoiveHeader}
  //           storeHeader={storeHeader}
  //           paidAmount={paidAmount}
  //           miscDetail={miscDetail}
  //           treatmentDetail={treatmentDetail}
  //           paymentOptions={paymentOptions}
  //         />
  //       }>
  //       {({ blob, url, loading, error }) => {
  //         // var file = new blob();
  //         var fileURL = [];
  //         if (!loading && blob && print !== printNum) {
  //           printNum = print;
  //           fileURL = URL.createObjectURL(blob);
  //           window.open(fileURL, "_blank", "noreferrer");
  //           setPrintingDate();
  //         }

  //         // Do whatever you need with blob here
  //         return <></>;
  //       }}
  //     </BlobProvider>
  //   );
  // } else {
  //   return <></>;
  // }
};

export default InvoicePDF;
